import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import Close from '@material-ui/icons/Close';
import { useFlagsmith } from 'flagsmith/react';
import { useTranslation } from '../../../i18n/infra/useTranslation';
import { Spacer } from '../../../shared';
import YoutubeEmbed from '../common/YoutubeEmbed';
import { WelcomeCard } from '../../../libs/hooks/useContentful';
import { Typography } from '../../../../src/design-system/atoms';
import { useAuthStore } from '../../infra/store/useAuthStore';
import { urls } from '../../../libs/urls';

const useStyles = makeStyles(theme => ({
  closeButton: { height: '32px', width: '32px' },
  contentBox: {
    padding: theme.spacing(1, 7),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
}));

export function TutorialDialog({
  open,
  onClose,
  card,
}: {
  open: boolean;
  onClose: () => void;
  card?: WelcomeCard;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const flagsmith = useFlagsmith();
  const authStore = useAuthStore();
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <Spacer spacing={5} />
      <DialogTitle>
        <Box
          position="absolute"
          width="32px"
          height="32px"
          margin={2}
          top="0px"
          right="0px"
        >
          <IconButton
            aria-label={t('HELPER_CLOSE_ICON')}
            edge="end"
            onClick={onClose}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">{card.title}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.contentBox}>
        {flagsmith.hasFeature('welcome_page_card_video') ? (
          <YoutubeEmbed title={card.title} videoId={card.dialogVideoUrl} />
        ) : null}
        <Box
          display="flex"
          justifyContent="center"
          marginTop={3}
          marginBottom={5}
        >
          <Typography variant="body2" color="textSecondary" align="center">
            {card.dialogBody}
          </Typography>
        </Box>
        <Button
          href={
            authStore.isTaxRegistered
              ? card.dialogButtonUrl
              : urls.taxCredentials
          }
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          {card.dialogButtonText}
        </Button>
        <Spacer spacing={6} />
      </DialogContent>
    </Dialog>
  );
}

TutorialDialog.defaultProps = {
  card: {
    order: 0,
    iconUrl: '/assets/authentication/icons/forward.svg',
    title: 'Adelantar cuentas por cobrar',
    dialogBody: '',
    dialogVideoUrl: '',
    dialogButtonText: '',
    dialogButtonUrl: '',
  },
};

import jwtDecode from 'jwt-decode';
import { IFlagsmith } from 'flagsmith/types';
import { AuthError } from '../domain/AuthError';
import { AuthStore } from '../domain/AuthStore';
import { LoginCredential } from '../domain/LoginCredential';
import { AuthRepository } from '../domain/AuthRepository';
import { EventsTrackerRepository } from '../domain/repository/EventsTrackerRepository';

export async function loginUseCase({
  loginCredential,
  authRepository,
  eventsTracker,
  authStore,
  featureFlagsManager,
}: {
  loginCredential: LoginCredential;
  authRepository: AuthRepository;
  eventsTracker: EventsTrackerRepository;
  authStore: AuthStore;
  featureFlagsManager: IFlagsmith;
}): Promise<void> {
  if (authStore.isLoading) return;
  authStore.startLoading();
  authStore.clearAuthError();
  try {
    const accessToken = await authRepository.login(loginCredential);
    const tokenInfo: { data: any } = jwtDecode(accessToken.value);
    const { business } = tokenInfo.data;
    eventsTracker.sendEvent('NEW_LOGIN_EVENT', { businessId: business.id });
    await featureFlagsManager.identify(business?.id);
    authStore.saveAccessToken(accessToken);
  } catch (error) {
    if (error instanceof AuthError) {
      authStore.setAuthError(error.message);
    }
  } finally {
    authStore.stopLoading();
  }
}

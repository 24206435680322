import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';

import { Box, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useRouter } from 'next/router';
import useForgotPassword from '../../adapters/ForgotPassword/useForgotPassword';
import useForgotPasswordStore from '../../infra/store/useForgotPasswordStore';
import { useAuthRepository } from '../../infra/repositories/useAuthRepository';
import { useTranslation } from '../../../i18n/infra/useTranslation';
import { useEmailRules } from '../../../../src/helpers/validations/rules/useEmailRules';
import { LoadingButton, Spacer, Link } from '../../../shared';
import { urls } from '../../../libs/urls';
import { useSubmitError } from '../../infra/hooks/useSubmitError';
import { Typography } from '../../../../src/design-system/atoms';

function ForgotPassword() {
  const authRepository = useAuthRepository();
  const forgotPasswordStore = useForgotPasswordStore();
  const router = useRouter();

  const { isLoading, isAuthError, onSubmit } = useForgotPassword({
    authRepository,
    forgotPasswordStore,
  });

  const { onSubmitError, submitError } = useSubmitError();

  const { control, handleSubmit, setError } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (isAuthError) {
      setError('email', {
        message: 'Credenciales incorrectas. Por favor intenta nuevamente',
      });
    }
  }, [isAuthError, setError]);

  const { t } = useTranslation();

  const emailRules = useEmailRules();

  return (
    <div>
      <Spacer spacing={isMobile ? 5 : 12} />
      <Typography component="div" variant="h3" align="center">
        <Box fontWeight="fontWeightMedium">Recuperar contraseña</Box>
      </Typography>
      <Spacer spacing={isMobile ? 1 : 2} />
      <Typography variant="body2" color="textSecondary" align="center">
        Te enviaremos un email con las instrucciones a seguir sobre cómo
        restablecer tu contraseña
      </Typography>
      <Spacer spacing={isMobile ? 4 : 5} />
      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <Controller
          name="email"
          rules={emailRules}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              variant="outlined"
              placeholder={t('PLACEHOLDER_EMAIL')}
              label={t('LABEL_EMAIL')}
              value={field.value || ''}
              type="text"
              fullWidth
              error={
                (fieldState.isTouched || !!submitError) && !!fieldState.error
              }
              helperText={
                fieldState.isTouched ||
                !!submitError ||
                (fieldState.error?.type === 'validate'
                  ? t('ERROR_INVALID_EMAIL')
                  : '')
              }
              autoFocus={!isMobile}
            />
          )}
        />

        {forgotPasswordStore.success ||
          (forgotPasswordStore.error && (
            <>
              <Spacer spacing={2} />
              <Alert severity="success">
                {t('NOTIFICATION_SUCCESS_EMAIL')}
              </Alert>
            </>
          ))}
        <Spacer spacing={isMobile ? 2 : 3} />
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          isLoading={isLoading}
        >
          Enviar email
        </LoadingButton>
      </form>

      <Spacer spacing={isMobile ? 2 : 3} />

      <Typography variant="body2" align="center">
        ¿Ya tienes tu cuenta?{' '}
        <Link href={{ pathname: urls.login, query: router.query }}>
          Ingresar
        </Link>
      </Typography>

      <Spacer spacing={isMobile ? 1 : 2} />

      <Typography variant="body2" align="center">
        ¿Todavía no tienes cuenta?{' '}
        <Link href={{ pathname: urls.register, query: router.query }}>
          Regístrate
        </Link>
      </Typography>
    </div>
  );
}

export default ForgotPassword;

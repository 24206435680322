import { useCallback, useEffect, useState } from 'react';
import { BrandLogo, useContentful } from './useContentful';

export const useCompanyLogos = (limit?: number) => {
  const [companyLogos, setCompanyLogos]: [
    BrandLogo[],
    React.Dispatch<React.SetStateAction<BrandLogo[]>>
  ] = useState([]);
  const { getCompaniesLogos } = useCallback(useContentful, [])();

  useEffect(() => {
    getCompaniesLogos({
      country: process.env.NEXT_PUBLIC_COUNTRY_ID,
      limit,
    }).then(brandLogos => setCompanyLogos(brandLogos));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return companyLogos;
};

import { useState } from 'react';
import { useTranslation } from '../../../i18n/infra/useTranslation';
import { ForgotPasswordStore } from '../../domain/ForgotPasswordStore';

const useForgotPasswordStore = (): ForgotPasswordStore => {
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  const dispatchInit = () => {
    setError(false);
    setSuccess(false);
    startLoading();
    setErrorMessage('');
  };
  const dispatchSuccess = () => {
    setSuccess(true);
  };
  const dispatchError = (errorCode: string) => {
    setError(true);
    const validErrorMessage = t(errorCode) || errorCode;
    setErrorMessage(validErrorMessage);
  };

  return {
    error,
    success,
    errorMessage,
    isLoading,
    startLoading,
    stopLoading,
    dispatchInit,
    dispatchSuccess,
    dispatchError,
  };
};

export default useForgotPasswordStore;

import { createClient } from 'contentful';
import { useFlagsmith } from 'flagsmith/react';
import { ReferralCardProps } from '@features/home/domain/widget/ReferralCard';
import { urls } from '../urls';

export interface WelcomeCard {
  order: number;
  iconUrl: string;
  title: string;
  dialogBody: string;
  dialogVideoUrl: string;
  dialogButtonText: string;
  dialogButtonUrl: string;
}

export interface BrandLogo {
  order: number;
  logoUrl: string;
  height: number;
  width: number;
  country: string;
}

export interface FAQ {
  id: number;
  contentType: string;
  pregunta: string;
  content: any;
  callToAction: {
    buttonText: string;
    actionLink: string;
  };
}

export interface SmartWidgetItem {
  label?: string;
  title: string;
  description?: string;
  step:
    | 'fill_tax'
    | 'fill_buro'
    | 'fill_legal'
    | 'loading_invoices'
    | 'onboarded'
    | 'engaged'
    | 're_enrolled'
    | 'empty_signup'
    | 'empty_loading';
  buttonText?: string;
  buttonLink?: string;
  productCards?: object[];
}

export const useContentful = () => {
  const featureFlagsManager = useFlagsmith();
  const client = createClient({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  });

  const getWelcomeCards = async (): Promise<WelcomeCard[]> => {
    try {
      const entries: { items: Array<any> } = await client.getEntries({
        content_type: 'saas',
        select: 'fields',
      });
      return entries.items
        .map(item => ({
          order: item.fields.order || 0,
          iconUrl: item.fields.icon.fields.file.url || '',
          title: item.fields.title || '',
          dialogBody: item.fields.dialogBody || '',
          dialogVideoUrl: item.fields.dialogVideo.fields.file.url || '',
          dialogButtonText: featureFlagsManager.hasFeature(
            'saas_register_full_register'
          )
            ? urls.taxRegister
            : item.fields.dialogButtonText || '',
          dialogButtonUrl: item.fields.dialogButtonUrl || '',
        }))
        .sort((item1, item2) => item1.order - item2.order);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error fetching welcome cards: ${error}`);
    }
    return [];
  };

  const getFAQs = async (): Promise<FAQ[]> => {
    try {
      const entries: { items: Array<any> } = await client.getEntries({
        content_type: 'faqContent',
        select: 'fields',
      });
      return entries.items
        .map(item => ({
          id: Number(item.fields.id) || 0,
          contentType: item.fields.contentType || '',
          pregunta: item.fields.pregunta || '',
          content: item.fields.content || '',
          callToAction: {
            buttonText: item.fields.callToAction?.buttonText || '',
            actionLink: item.fields.callToAction?.actionLink || '',
          },
        }))
        .sort((item1, item2) => item1.id - item2.id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error fetching faqs: ${error}`);
    }
    return [];
  };

  interface GetCompaniesLogosProps {
    country: string;
    limit?: number;
  }

  const getCompaniesLogos = async ({
    country,
    limit,
  }: GetCompaniesLogosProps): Promise<BrandLogo[]> => {
    try {
      const entries: { items: Array<any> } = await client.getEntries({
        content_type: 'taxRegisterBrands',
        select: 'fields',
        'fields.country': country,
        limit: limit || 50,
      });
      return entries.items
        .map(item => ({
          order: Number(item.fields.order) || 0,
          logoUrl: `${item.fields.logo.fields.file.url}`,
          height: item.fields.height || 58.58,
          width: item.fields.width || 100,
          country: item.fields.country || 'CL',
        }))
        .sort((item1, item2) => item1.order - item2.order);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error fetching tax companies: ${error}`);
    }
    return [];
  };

  const getSmartWidgetContent = async (
    country: string
  ): Promise<SmartWidgetItem[]> => {
    try {
      const entries: { items: Array<any> } = await client.getEntries({
        content_type: 'smartWidget',
        select: 'fields',
        locale: `es-${country}`,
      });

      return entries.items.map(item => ({
        label: item.fields.label || '',
        title: item.fields.title || '',
        description: item.fields.description || '',
        step: item.fields.step || '',
        buttonText: item.fields.buttonText || '',
        productCards: item.fields.productCards || [],
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error fetching smart widget items: ${error}`);
    }
    return [];
  };

  const getReferralCardContent = async (): Promise<ReferralCardProps[]> => {
    try {
      const entries: { items: Array<any> } = await client.getEntries({
        content_type: 'homeReferralWidget',
        select: 'fields',
      });

      return entries.items.map(item => ({
        label: item.fields.label || '',
        header: item.fields.header || '',
        subtitle: item.fields.subtitle || '',
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error fetching smart widget items: ${error}`);
    }
    return [];
  };

  return {
    getFAQs,
    getCompaniesLogos,
    getWelcomeCards,
    getSmartWidgetContent,
    getReferralCardContent,
  };
};

import { useRouter } from 'next/router';
import { Box } from '@material-ui/core';
import { useFlagsmith } from 'flagsmith/react';
import useLogin from '../../adapters/Login/useLogin';
import { useAuthStore } from '../../infra/store/useAuthStore';
import { useEventsTracker } from '../../infra/eventHandlers/useEventsTracker';
import { useAuthRepository } from '../../infra/repositories/useAuthRepository';
import { usePageStyles } from '../common/styles';
import { urls } from '../../../libs/urls';
import { Link, Spacer } from '../../../shared';
import { CountryFlagSelector } from '../common/CountryFlagSelector';
import { Typography } from '../../../../src/design-system/atoms';
import LoginForm from './LoginForm';
import useRedirectWhenLogged from './hooks/useRedirectWhenLogged';

function Login() {
  const classes = usePageStyles();
  const authStore = useAuthStore();
  const authRepository = useAuthRepository();
  const eventsTracker = useEventsTracker();
  const featureFlagsManager = useFlagsmith();
  const router = useRouter();

  const { onSubmit, isLoading } = useLogin({
    authRepository,
    authStore,
    eventsTracker,
    router,
    featureFlagsManager,
  });

  useRedirectWhenLogged(authStore);

  return (
    <Box maxWidth="420px" textAlign="center">
      <CountryFlagSelector />
      <Spacer spacing={2} />
      <Typography component="div" variant="h3" className={classes.title}>
        <Box fontWeight="fontWeightMedium">Ingresa al Ecosistema Xepelin</Box>
      </Typography>

      <LoginForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        authError={authStore.authError}
      />

      <Spacer spacing={2} />

      <Typography variant="body2" className={classes.mb2}>
        ¿Todavía no tienes cuenta?{' '}
        <Link href={{ pathname: urls.register, query: router.query }}>
          Regístrate
        </Link>
      </Typography>

      <Link
        variant="body2"
        href={{ pathname: urls.forgotPassword, query: router.query }}
      >
        Recuperar acceso a mi cuenta
      </Link>
    </Box>
  );
}

export default Login;

import { makeStyles, Box } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  box: {
    height: '276px',
    borderRadius: '8px',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: '176px',
    },
  },
  video: {
    height: '276px',
    borderRadius: '8px',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: '176px',
    },
  },
}));

function YoutubeEmbed({
  title,
  videoId,
  width,
}: {
  title: string;
  videoId: string;
  width?: string;
}) {
  const classes = useStyles();
  return (
    <Box borderRadius={8} width={width} className={classes.box}>
      <iframe
        width={width}
        className={classes.video}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
      />
    </Box>
  );
}

YoutubeEmbed.defaultProps = {
  width: '100%',
};

export default YoutubeEmbed;

import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Image from 'next/image';
import React, { useCallback, useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useCompanyLogos } from '../../../libs/hooks/useCompanyLogos';
import { Spacer } from '../../../shared';
import { TutorialDialog } from './TutorialDialog';
import { useMobile } from '../../../home/infra/context/useMobile';
import { useContentful, WelcomeCard } from '../../../libs/hooks/useContentful';
import { CardSkeleton } from './CardSkeleton';
import { useEventsTracker } from '../../infra/eventHandlers/useEventsTracker';
import { Typography } from '../../../../src/design-system/atoms';
import { urls } from '../../../libs/urls';

const useStyles = makeStyles(theme => ({
  card: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[200],
    },
    minHeight: '143px',
  },
  '@keyframes pulseAnimation': {
    '0%': {
      width: '60px',
      height: '60px',
      opacity: 0.8,
    },
    '100%': {
      width: '92px',
      height: '92px',
      opacity: 0,
      transform: 'translateX(-16px) translateY(-16px)',
    },
  },
  circularPulse: {
    background:
      'linear-gradient(56.45deg, #FF7D00 1.78%, #FC771C 16.06%, #F56565 51.07%, #EA4AD8 98.59%, #E640FF 104.65%)',
    position: 'absolute',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    animation: `$pulseAnimation 1.25s infinite`,
  },
  brandSkeleton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export default function Welcome() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [welcomeCards, setWelcomeCards] = useState([]);
  const companyLogos = useCompanyLogos(6);
  const [selectedCard, setSelectedCard]: [
    WelcomeCard,
    React.Dispatch<React.SetStateAction<WelcomeCard>>
  ] = useState();
  const isMobile = useMobile();
  const eventsTracker = useEventsTracker();
  const { getWelcomeCards } = useCallback(useContentful, [])();

  useEffect(() => {
    getWelcomeCards().then(cards => setWelcomeCards(cards));
    if (eventsTracker) {
      setTimeout(() => {
        eventsTracker.sendEvent('WELCOME_PAGE_ON_LOAD', {
          source: 'SHOW_PRODUCTS_AFTER_REGISTER',
        });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCardClick = (card: WelcomeCard) => {
    eventsTracker.sendEvent('WELCOME_PRODUCT_CARD_CLICK', {
      action: card.dialogButtonText,
    });
    setOpen(true);
    setSelectedCard(card);
  };

  return (
    <Container>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Spacer spacing={isMobile ? 5 : 9} />
        <Box position="relative" alignSelf="center" margin="32px 0px">
          <div className={classes.circularPulse} />
          <Image
            src="/assets/authentication/icons/xepelinCircularLogo.svg"
            height={60}
            width={60}
            unoptimized
          />
        </Box>
        <Spacer spacing={2} />
        <Typography variant="h2" align="center">
          Registro finalizado con éxito
        </Typography>
        <Spacer spacing={isMobile ? 1 : 2} />
        <Typography variant="body2" color="textSecondary" align="center">
          Ya eres parte de la nueva generación de soluciones financieras para tu
          negocio.
        </Typography>
        <Spacer spacing={isMobile ? 4 : 9} />
        <Divider />
        <Spacer spacing={isMobile ? 4 : 9} />
        <Typography variant="h4" align={isMobile ? 'center' : 'left'}>
          ¿Qué deseas hacer con tu cuenta Xepelin?
        </Typography>
        <Spacer spacing={isMobile ? 3 : 6} />
        <Grid container spacing={3}>
          {welcomeCards.length
            ? welcomeCards.map(card => (
                <Grid item key={card?.title} xs={6} md={4}>
                  <Card
                    className={classes.card}
                    onClick={() => onCardClick(card)}
                  >
                    <Container>
                      <Spacer spacing={2} />
                      <Grid container justifyContent="flex-start">
                        <Image
                          src={card.iconUrl}
                          width={32}
                          height={32}
                          unoptimized
                        />
                      </Grid>
                      <Spacer spacing={2} />
                      <Typography variant="h6" align="left">
                        {card.title}
                      </Typography>
                      <Spacer spacing={2} />
                    </Container>
                  </Card>
                </Grid>
              ))
            : [...Array(6)].map((e, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={`skeleton ${i}`} xs={6} md={4}>
                  <CardSkeleton className={classes.card} />
                </Grid>
              ))}
        </Grid>
        <TutorialDialog
          open={open}
          onClose={() => setOpen(false)}
          card={selectedCard}
        />
        <Spacer spacing={5} />
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            href={urls.root}
          >
            Ir al home
          </Button>
        </Box>
        <Spacer spacing={isMobile ? 4 : 9} />
        <Divider />
        <Spacer spacing={isMobile ? 4 : 9} />
        <Typography variant="h4" align={isMobile ? 'center' : 'left'}>
          Empresas que confían en nosotros
        </Typography>
        <Spacer spacing={isMobile ? 3 : 6} />
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {companyLogos.length
            ? companyLogos.map(company => {
                const height = isMobile ? 40 : 58.58;
                return (
                  <Grid key={company.logoUrl} item>
                    <Box
                      position="relative"
                      height={height}
                      width={(company.width / company.height) * height}
                    >
                      <Image src={company.logoUrl} layout="fill" unoptimized />
                    </Box>
                  </Grid>
                );
              })
            : [...Array(7)].map((_, i) => (
                <Skeleton
                  // eslint-disable-next-line react/no-array-index-key
                  key={`brand skeleton ${i}`}
                  className={classes.brandSkeleton}
                  component="rect"
                  animation="wave"
                  width={isMobile ? 100 : 120}
                  height={isMobile ? 40 : 58.58}
                />
              ))}
        </Grid>
        <Spacer spacing={isMobile ? 4 : 9} />
        <Divider />
      </Box>
    </Container>
  );
}

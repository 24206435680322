import { Card, Container, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Spacer } from '../../../shared';

export function CardSkeleton({ className }: { className: string }) {
  return (
    <Card className={className}>
      <Container>
        <Spacer spacing={2} />
        <Grid container justifyContent="flex-start">
          <Skeleton animation="wave" variant="circle" width={32} height={32} />
        </Grid>
        <Spacer spacing={2} />
        <Skeleton animation="wave" variant="rect" />
        <Spacer spacing={2} />
      </Container>
    </Card>
  );
}

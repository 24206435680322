import { ForgotPassword } from '../../domain/ForgotPassword';
import { AuthRepository } from '../../domain/AuthRepository';
import { forgotPasswordUseCase } from '../../useCases/ForgotPasswordUseCase';
import { ForgotPasswordStore } from '../../domain/ForgotPasswordStore';

function useForgotPassword({
  authRepository,
  forgotPasswordStore,
}: {
  authRepository: AuthRepository;
  forgotPasswordStore: ForgotPasswordStore;
}) {
  const onSubmit = async ({ email }) => {
    const forgotPassword: ForgotPassword = { email };
    await forgotPasswordUseCase({
      forgotPassword,
      authRepository,
      forgotPasswordStore,
    });
  };

  return {
    onSubmit,
    isLoading: forgotPasswordStore.isLoading,
    isAuthError: forgotPasswordStore.error,
  };
}

export default useForgotPassword;

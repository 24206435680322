import { ForgotPassword } from '../domain/ForgotPassword';
import { AuthRepository } from '../domain/AuthRepository';
import { ForgotPasswordStore } from '../domain/ForgotPasswordStore';

export async function forgotPasswordUseCase({
  forgotPassword,
  authRepository,
  forgotPasswordStore,
}: {
  forgotPassword: ForgotPassword;
  authRepository: AuthRepository;
  forgotPasswordStore: ForgotPasswordStore;
}) {
  forgotPasswordStore.dispatchInit();

  try {
    await authRepository.forgotPassword(forgotPassword);
    forgotPasswordStore.dispatchSuccess();
  } catch (error) {
    forgotPasswordStore.dispatchError(error?.message);
  } finally {
    forgotPasswordStore.stopLoading();
  }
}
